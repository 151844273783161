import styled from 'styled-components';
import { darken } from 'polished';

interface TableContentProps {
  showHeader: boolean;
  rowIsClickable: boolean;
}

export const TableContent = styled.div<TableContentProps>`
  border-radius: 10px;
  /* overflow: hidden; */
  background-color: #1d2023;

  h2 {
    color: #dadada;
  }

  .height {
    height: 20px;
  }
  .border-gray {
    border: solid 1px #333;
  }
  .menu-table {
    z-index: 100;
    top: 15px;
    left: 15px;
    right: 15px;

    .search {
      padding: 1px;
      border-radius: 100px;

      div {
        background: #1d2023;
        border-radius: 100px;
      }

      input {
        margin: 5px 0;
        padding: 0 10px;
        border: none;
        border-radius: 100px;
        background-color: #1d2023;
        color: #fdfdfd;
      }
    }

    .inputDate {
      border: 1px solid #333333;
      background: #1d2023;
      color: #828282;
      padding: 5px 10px;
      border-radius: 100px;
    }
  }

  div.tableData {
    min-height: 350px;
    .rdt_TableHeader {
      display: ${(props) => (props.showHeader ? 'block' : 'none')};
    }

    div {
      background-color: #1d2023;
    }

    .rdt_TableRow {
      cursor: ${(props) => (props.rowIsClickable ? 'pointer' : 'default')};
      transition-duration: 0.3s;

      div {
        transition-duration: 0.3s;
      }

      :hover {
        background-color: ${darken(0.03, '#1D2023')};

        div {
          background-color: ${darken(0.03, '#1D2023')};
        }
      }
    }
  }

  @media screen and (max-width: 1399px) {
    div.tableData {
      max-width: 1200px;
    }
  }

  @media screen and (max-width: 991px) {
    .react-datepicker-wrapper {
      width: 100%;
    }
  }

  @media screen and (max-width: 355px) {
    .size-xxs {
      font-size: 12px;
      padding: 8px 10px !important;
    }

    .svg-xxs {
      img {
        width: 16px !important;
      }
    }
  }
`;

export const Pagination = styled.div`
  p,
  button {
    color: #828282;
  }

  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: bold;

    :first-child,
    :last-child {
      width: auto;
      border-radius: 5px;
    }
  }

  button.active,
  button:hover:not(:disabled) {
    color: #000;
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
  }

  @media screen and (max-width: 991px) {
    .btn-page:not(.active) {
      display: none;
    }
  }
`;

export const NotFound = styled.div`
  display: flex;
  width: 100%;
  height: 350px;
  justify-content: center;
  align-items: center;
  color: #8d8d8d;
`;
