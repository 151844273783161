import React, { useCallback, useState, useMemo } from 'react';
import Lottie from 'react-lottie';
import { HiArrowNarrowRight } from 'react-icons/hi';
import attention from '~/assets/animations/attention.json';
import { Modal } from './styles';

interface IShow {
  show: boolean;
  aaName: string;
  handleCancel(): void;
  handleAccept(): void;
}

const ModalPleaseConfirm: React.FC<IShow> = ({
  show,
  aaName,
  handleCancel,
  handleAccept,
}) => {
  return (
    <>
      <Modal size="lg" centered show={show} className="modal-wrong-network">
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-4">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden pe-none">
            <Lottie
              options={{
                animationData: attention,
                autoplay: true,
                loop: true,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={160}
              width={160}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5 mt-n5">
          <h2 className="mb-1 fw-bold text-center w-100">Please Confirm...</h2>
          <p className="text-center complete mb-4">
            You are about to complete your registration
          </p>
          <div className="texts w-lg-75 mx-auto">
            <h3 className="text-center">
              This is your referrer to the platform:
            </h3>
            <h4 className="text-center mb-4">{aaName}</h4>
            <p className="anything-text text-center mb-4">
              If anything seems incorrect, please reach back out to
              AutoAffiliate support immediately. Remember, transactions on the
              blockchain are irreversible.
            </p>

            <p className="text-center my-4">
              <span>Please note:</span> All{' '}
              <span>subscription sales are final.</span> Due to the nature of
              blockchain, <span>refunds are not available.</span>
            </p>

            <button
              type="button"
              className="btn-confirm w-100 mb-3"
              onClick={handleAccept}
            >
              FINALIZE REGISTRATION{' '}
              <HiArrowNarrowRight className="ms-3" color="#fff" size={28} />
            </button>

            <button
              type="button"
              onClick={handleCancel}
              className="btn-cancel w-100"
            >
              <span className="">Cancel</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
    </>
  );
};

export default ModalPleaseConfirm;
