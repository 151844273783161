import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  ChangeEvent,
} from 'react';
import { useLocation } from 'react-router-dom';
import {
  ethers,
  utils,
  ContractReceipt,
  ContractTransaction,
  BigNumber,
} from 'ethers';
import Swal from 'sweetalert2';
import Slider from 'react-slick';
import Lottie from 'react-lottie';
import { useAuth } from '~/hooks/Auth';
import api from '~/services/api';
import {
  getConfigAddress,
  getConfigNumber,
  getConfigString,
} from '~/utils/configContract';
import { web3store } from '../../store';
import earningsDone from '~/assets/animations/earnings-done.json';

import { Container, Modal, ModalFinish } from './styles';
import ModalRetireMoney, {
  IFormData as ModalRetireMoneyFormData,
} from '../ModalRetireMoney';
import { formatPrice } from '~/utils/format';
import swalError from '~/utils/swalError';
import aiverifyGif from '~/assets/animations/aiverify.gif';
import logoWhite from '~/assets/logo/logo-p-white.svg';
import Loading from '~/components/Loading';
import ModalNetworkSwitch, {
  networkSwitch,
} from '~/components/ModalNetworkSwitch';

interface IWallet {
  id: string;
  amount: number;
  cripto_address: string;
}

interface ICenter {
  id: number;
  earnings: string;
}

interface IEarning {
  total: string;
  centers: ICenter[];
}

interface IEarningResponse {
  earnings: {
    day: IEarning;
    wtd: IEarning;
    mtd: IEarning;
    ytd: IEarning;
    available: string;
  };
}

interface WithdrawalRequest {
  nftId: number;
  userAddress: string;
  message: string;
  deadline: number;
  transaction_fee: BigNumber;
}

/* eslint no-underscore-dangle: 0 */
const MyEarnings: React.FC = () => {
  const { user } = useAuth();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processText, setProcessText] = web3store.useState('processText');
  const [active, setActive] = useState('Day');
  const [activeCenter, setActiveCenter] = useState(0);
  const [userChanged, setUserChanged] = useState(false);
  // const [runWithdrawal, setRunWithdrawal] = web3store.useState('runWithdrawal');
  const [withdrawalResult, setWithdrawalResult] =
    web3store.useState('withdrawalResult');
  const [walletAddPRFTI, setWalletAddPRFTI] =
    web3store.useState('walletAddPRFTI');
  const [walletAddUSDPI, setWalletAddUSDPI] =
    web3store.useState('walletAddUSDPI');
  const [addPRFTI, setAddPRFTI] = useState(false);
  const [addUSDPI, setAddUSDPI] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const explorerURL = process.env.REACT_APP_NETWORK_BLOCK_EXPLORER_URLS;
  const [chainMode, setChainMode] = web3store.useState('chainMode');
  const [earningsDay, setEarningsDay] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earningsWtd, setEarningsWtd] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earningsMtd, setEarningsMtd] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earningsYtd, setEarningsYtd] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [earnings, setEarnings] = useState<IEarning>({
    total: '0.00',
    centers: [],
  });
  const [showFinish, setShowFinish] = useState(false);
  const [depositTX, setDepositTX] = useState('');
  const [available, setAvailable] = useState('0.00');
  const [wallet, setWallet] = useState<IWallet>({} as IWallet);
  const [account, setAccount] = web3store.useState('account');
  const [isValid, setIsValid] = useState(true);
  const [errorTextClaim, setErrorTextClaim] = useState('');

  const filterCalendar = useMemo(() => ['Day', 'Week', 'Month', 'Year'], []);

  const settings = useMemo(
    () => ({
      dots: true,
      infinite: false,
      arrows: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 2,
    }),
    []
  );

  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (user && (fetchData || userChanged)) {
      api
        .get<IEarningResponse>(`/v2/member/earningtotals/${user?.id}`)
        .then((response) => {
          console.log(response.data);
          const centersDay = response.data.earnings.day.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarnings({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.day.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersDay,
          });
          setEarningsDay({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.day.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersDay,
          });

          const centersWtd = response.data.earnings.wtd.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarningsWtd({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.wtd.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersWtd,
          });

          const centersMtd = response.data.earnings.mtd.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarningsMtd({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.mtd.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersMtd,
          });

          const centersYtd = response.data.earnings.ytd.centers.map<ICenter>(
            (center) => ({
              id: center.id,
              earnings: formatPrice(
                parseFloat(
                  (parseInt(center.earnings, 10) / 10 ** 18).toFixed(2)
                )
              ),
            })
          );
          setEarningsYtd({
            total: formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.ytd.total, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            ),
            centers: centersYtd,
          });

          setAvailable(
            formatPrice(
              parseFloat(
                (
                  parseInt(response.data.earnings.available, 10) /
                  10 ** 18
                ).toFixed(2)
              )
            )
          );
        })
        .finally(() => {
          setFetchData(false);
          setUserChanged(false);
        });
    }
  }, [user, fetchData, userChanged]);

  useEffect(() => {
    setUserChanged(true);
  }, [user]);

  const handleActiveCalendar = useCallback(
    (calendar: string) => {
      if (calendar === 'Day') {
        setEarnings(earningsDay);
      }

      if (calendar === 'Week') {
        setEarnings(earningsWtd);
      }

      if (calendar === 'Month') {
        setEarnings(earningsMtd);
      }

      if (calendar === 'Year') {
        setEarnings(earningsYtd);
      }

      setActive(calendar);
    },
    [earningsDay, earningsMtd, earningsWtd, earningsYtd]
  );

  const handleActiveCenter = (center_id: number) => {
    setActiveCenter(center_id);
  };

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValueClaim = e.target.value;
    // Check if there are extra decimal places
    const parts = inputValueClaim.split('.');
    if (parts.length === 2 && parts[1].length > 2) {
      // Truncate the extra decimal places
      parts[1] = parts[1].substring(0, 2);
      inputValueClaim = parts.join('.');
    }

    // Remove leading zeros before the integer part
    if (!inputValueClaim.startsWith('.') && inputValueClaim !== '0') {
      inputValueClaim = inputValueClaim.replace(/^0+/, '');
    }

    // Check if the input matches the USD format
    const usdRegex = /^(?:\$?\d{1,5}(?:,\d{3})*(\.\d{1,2})?|\$?99999.99)/;

    let isValidInput = true;
    if (inputValueClaim !== '') {
      isValidInput = usdRegex.test(inputValueClaim);
    }

    const inputValueFloat = parseFloat(inputValueClaim);

    // Add one leading zero if the float amount is less than 1 and not already present
    if (
      inputValueFloat < 1 &&
      !inputValueClaim.startsWith('0') &&
      inputValueClaim.startsWith('.')
    ) {
      inputValueClaim = '0'.concat(inputValueClaim);
    }

    const availableFloat = parseFloat(available.replace(/,/g, ''));
    let errorTextValidate = '';

    if (usdRegex.test(inputValueClaim)) {
      if (inputValueFloat === 0) {
        errorTextValidate = '';
        isValidInput = true;
      } else if (inputValueFloat < 5) {
        errorTextValidate = 'Minimum amount is $5.00';
        isValidInput = false;
      } else if (inputValueFloat > availableFloat) {
        errorTextValidate = 'Amount is greater than balance.';
        isValidInput = false;
      }
    } else {
      errorTextValidate = 'Please enter a valid USD amount.';
    }
    // Set the error text conditionally
    // const errorTextValidate =
    //   inputValueClaim === ''
    //     ? ''
    //     : 'Please enter a valid USD amount (e.g., $1,234.56)';

    // Update the state and show validation feedback
    setIsValid(isValidInput);
    if (inputValueClaim === '0') {
      setInputValue('');
    } else {
      setInputValue(inputValueClaim);
    }
    setErrorTextClaim(errorTextValidate);
    // You can perform any action here or pass the new value to another function/component
    // For example, you can call a function like handleValueChange(newValue);
  };

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleWithdraw = useCallback(async () => {
    const amt2Claim = parseFloat(inputValue.replace(/,/g, ''));
    if (amt2Claim > 0 && amt2Claim <= parseFloat(available.replace(/,/g, ''))) {
      if (user) {
        networkSwitch('NETWORK')
          .then((response) => {
            const amtFloat = parseFloat(inputValue.replace(/,/g, ''));
            console.log('amtFloat: %s', amtFloat);
            if (amtFloat > 1) {
              setProcessText('Preparing withdrawal transaction');
              setLoading(true);
              // setRunWithdrawal(true);
              // GET FEE ESTIMATE
              api
                .get('/v1/net/feeWithdrawal/estimate')
                .then(function (feeresponse) {
                  console.log(feeresponse.data);
                  // const fee = feeresponse.data.estimate.fee;
                  const { fee } = feeresponse.data.estimate;
                  // processOrder(response.data.estimate.fee);
                  // const purser = process.env.REACT_APP_BSC_PURSER;
                  getConfigAddress('contract_purser_v2')
                    .then((purserresult) => {
                      const purser = purserresult?.toString();
                      // console.log('purser: %s', purser);
                      // console.log('verifyingContract: %s', purser);
                      const deadline = Math.floor(Date.now() / 1000) + 600; // 10 minutes from now
                      console.log('deadline: %s', deadline);
                      const chainNo = process.env.REACT_APP_NETWORK_CHAIN_ID;
                      const chainInt = parseInt(chainNo || '0', 16);
                      console.log(chainInt);
                      // const purserold = process.env.REACT_APP_BSC_PURSER;
                      // console.log(purserold, typeof purserold);
                      const domain = {
                        name: 'Purser',
                        version: '2',
                        chainId: chainInt,
                        verifyingContract: purser,
                      };
                      console.log('++++++++++++++++++++++++++++++++++++++++++');
                      console.log(domain);
                      console.log('++++++++++++++++++++++++++++++++++++++++++');
                      const types = {
                        Request: [
                          { name: 'nftId', type: 'uint256' },
                          { name: 'userAddress', type: 'address' },
                          { name: 'message', type: 'string' },
                          { name: 'deadline', type: 'uint256' },
                          { name: 'transaction_fee', type: 'uint256' },
                        ],
                      };
                      const transaction_fee = BigNumber.from(fee);
                      const transaction_fee_in_ether = parseFloat(
                        ethers.utils.formatEther(transaction_fee)
                      );
                      const message = `I authorize this withdrawal of $${amtFloat.toFixed(
                        2
                      )}, less a transaction fee of $${transaction_fee_in_ether.toFixed(
                        2
                      )}`;
                      console.log(message);
                      const request: WithdrawalRequest = {
                        nftId: parseInt(user.id, 10),
                        userAddress: account,
                        message,
                        deadline,
                        transaction_fee: BigNumber.from(fee),
                      };
                      const tprovider = new ethers.providers.Web3Provider(
                        window.ethereum
                      );
                      // console.log('+++++ Wei Value +++++ ');
                      // console.log((amtFloat * 1e18).toString());
                      // console.log(
                      //   ethers.utils.parseEther(amtFloat.toString()).toString()
                      // );
                      // console.log('+++++ Wei Value +++++ ');
                      setProcessText('Waiting for signature from wallet.');
                      const tsigner = tprovider.getSigner();
                      tsigner
                        ._signTypedData(domain, types, request)
                        .then((sig) => {
                          // Handle the result (sig) here
                          // console.log('signature: %s', sig);
                          setProcessText(
                            'Sending transaction to the blockchain.'
                          );
                          api
                            .post('/v2/member/withdraw', {
                              nftId: user.id,
                              userAddress: account,
                              message,
                              deadline,
                              signature: sig,
                              transaction_fee: fee,
                              amount_requested: ethers.utils
                                .parseEther(amtFloat.toString())
                                .toString(),
                            })
                            .then(function (withdrawalresponse) {
                              console.log(withdrawalresponse);
                              setLoading(false);
                              setDepositTX(withdrawalresponse.data.tx);
                              setShowFinish(true);
                              // processOrder(response.data.estimate.fee);
                              // setWithdrawalResult(response.data.tx);
                            })
                            .catch(function (withdrawalerror) {
                              console.log('============ ERROR ==============');
                              console.log(withdrawalerror);
                              setLoading(false);
                              // setWithdrawalResult('ERROR');
                              swalError({
                                message:
                                  'Sorry, there was a problem processing your withdrawal',
                                textButton: 'Close',
                              });
                            });
                        })
                        .catch((sigerror) => {
                          // Handle any errors that occur during the function call
                          console.log(sigerror);
                          setLoading(false);
                        });
                    })
                    .catch((purserError) => {
                      console.log(purserError);
                      setLoading(false);
                      swalError({
                        message:
                          'Sorry, there was a problem processing your withdrawal',
                        textButton: 'Close',
                      });
                    });
                })
                .catch(function (feeerror) {
                  console.log('================= ERROR ===================');
                  console.log(feeerror);
                  setLoading(false);
                  swalError({
                    message:
                      'Sorry, there was a problem getting network fees for this transaction',
                    textButton: 'Close',
                  });
                });
            }
          })
          .catch((error) => {
            console.log(error);
            setLoading(false);
          });
      }
      //  if (amt2Claim > 0 && amt2Claim <= parseFloat(available.replace(/,/g, '')))
    } else {
      console.log('Invalid withdrawal value');
    }
  }, [available, inputValue]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleMaxBtn = useCallback(async () => {
    console.log('+++++ available: %s +++++', available);
    setInputValue(available.replace(/,/g, ''));
    setIsValid(true);
  }, [available]);

  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  // ++++++++++++++++++++++++++++++++++++++
  const handleClearBtn = useCallback(async () => {
    console.log('+++++ available: %s +++++', available);
    setInputValue('');
    setIsValid(true);
  }, [available]);

  useEffect(() => {
    setLoading(false);
    if (withdrawalResult.length > 0 && withdrawalResult !== 'ERROR') {
      handleShow();
      setFetchData(true);
    } else {
      setWithdrawalResult('');
    }
  }, [withdrawalResult]);

  const handleClose = useCallback(() => {
    setShowModal(false);
    setShow(false);
    setShowFinish(false);
  }, []);

  const handleClickPRFTIYes = useCallback(() => {
    setWalletAddPRFTI(true);
    setAddPRFTI(false);
  }, [setWalletAddPRFTI]);

  const handleClickUSDPIYes = useCallback(() => {
    setWalletAddUSDPI(true);
    setAddUSDPI(false);
  }, [setWalletAddUSDPI]);

  const handleSubmit = useCallback(
    async (data: ModalRetireMoneyFormData) => {
      try {
        // const response = await api.post('wallets/withdraw', data);
        Swal.fire(
          'Tudo certo!',
          'Saque realizado com sucesso.',
          'success'
        ).then(() => {
          // setWallet(response.data);
          handleClose();
        });
      } catch (error) {
        const err: any = error;
        swalError({
          message: err.response.data.message,
          textButton: 'Close',
        });
      }
    },
    [handleClose]
  );

  return (
    <Container className="p-3 py-5 h-100">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <small className="TitleBox font-weight-bold">Total Earnings</small>
        </div>
        <div className="col-lg-12">
          <span className="ValueBox">${earnings.total}</span>
        </div>
        <div className="col-lg-12 TimeBox">
          <div className="d-flex justify-content-around">
            {filterCalendar.map((calendar) => (
              <button
                type="button"
                onClick={() => handleActiveCalendar(calendar)}
                className={`border-0 bg-transparent ItemTimeBox ${
                  active === calendar && 'Active'
                }`}
              >
                {calendar}
              </button>
            ))}
          </div>
        </div>
        <div className="col-lg-12 text-center MonthBox">
          <Slider {...settings}>
            {earnings.centers.map((center) => (
              <button
                type="button"
                className={`border-0 bg-transparent rounded-pill ItemMonthBox w-25 ${
                  activeCenter === center.id && 'Active'
                }`}
                onClick={() => handleActiveCenter(center.id)}
              >
                {`Center ${center.id}`}
              </button>
            ))}
          </Slider>
        </div>
        <div className="col-11 col-sm-10 p-3 py-5 mt-5 WithDrawBox">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="row">
                <div className="col-sm-12 col-lg-12 TitleWithDrawBox">
                  <small>Available&nbsp;Earnings</small>
                </div>
              </div>
              <div className="ValueWithDrawBox mt-3 mb-4">
                <span className="h3 font-weight-400">${available}</span>
              </div>
            </div>

            {/* david asked not to climb this part yet /david pediu para deixar essa parte oculta */}

            <div className="col-12 px-5 mb-3">
              <div className="bg-box-input amount-stake">
                <div className="bd-input d-flex justify-content-between mt-3 p-3">
                  <button
                    type="button"
                    className="btn-max px-3"
                    onClick={handleClearBtn}
                  >
                    Clear
                  </button>
                  <input
                    type="number"
                    placeholder="Enter amount"
                    className="w-90 text-center"
                    name=""
                    id=""
                    value={inputValue}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    className="btn-max px-3 ms-3"
                    onClick={handleMaxBtn}
                  >
                    Max
                  </button>
                </div>
              </div>
            </div>
            <div className="col-12 px-3 px-5 mt-3 ButtonBox">
              <div className="border-gradient-light w-100 border-rad">
                {isValid ? (
                  <button
                    className="btn ButtonWithDrawBox w-100"
                    type="button"
                    onClick={() => {
                      handleWithdraw();
                      // handleShow();
                    }}
                  >
                    Claim
                  </button>
                ) : (
                  <p className="error-message text-center pt-1">
                    {errorTextClaim}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        size="xl"
        show={show}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <Modal.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: earningsDone,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </Modal.Header>
        <Modal.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Earnings Successfully Claimed!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-5">
              Your earnings were successfully claimed from the smart contract to
              your MetaMask&nbsp;wallet.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${withdrawalResult}`}
                target="_blank"
                rel="noreferrer"
              >
                {withdrawalResult}
              </a>
            </div>
            <p className="mb-4">
              Use the buttons below to add the Token contract to your MetaMask
              if you have not done it yet.
            </p>
            <button
              type="button"
              className="btn-add-usdpi w-100 mb-4"
              onClick={handleClickUSDPIYes}
            >
              <span className="">Add USDPI To Wallet</span>
            </button>
            <button
              type="button"
              className="btn-add-prfti w-100 mb-3"
              onClick={handleClickPRFTIYes}
            >
              <span className="">Add PRFTI To Wallet</span>
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className="border-0 py-4" />
      </Modal>
      <ModalFinish
        size="xl"
        show={showFinish}
        onHide={handleClose}
        className="modal-wrong-network"
      >
        <button
          type="button"
          className="h4 modal-close m-3 mb-0 ms-auto border-0 bg-transparent"
          onClick={handleClose}
        >
          x
        </button>
        <ModalFinish.Header className="justify-content-center border-0 pb-0 pb-lg-4 pt-5">
          <div className="my-n5 d-flex align-items-center justify-content-center overflow-hidden">
            <Lottie
              options={{
                animationData: earningsDone,
                autoplay: true,
                loop: false,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
              height={220}
              width={300}
            />
          </div>
        </ModalFinish.Header>
        <ModalFinish.Body className="px-4 px-sm-5">
          <h2 className="mb-4 fw-bold text-center w-100">
            Withdrawal Complete!
          </h2>
          <div className="texts w-75 mx-auto">
            <p className="text-center mb-4 px-xl-5">
              Your USDPI should arrive in your wallet in a few moments.
            </p>
            <p className="text-center">Here is the transaction hash:</p>
            <div className="mb-5">
              <a
                href={`${explorerURL}/tx/${depositTX}`}
                target="_blank"
                rel="noreferrer"
              >
                {depositTX}
              </a>
            </div>
            {/* <p className="mb-4">
              * Unpaid subscription invoices are automatically paid once your
              account has enough funds from commissions and deposits.
            </p> */}
          </div>
        </ModalFinish.Body>
        <ModalFinish.Footer className="border-0 py-4" />
      </ModalFinish>
      <Loading
        type="dark"
        srcImg={logoWhite}
        text="PLEASE WAIT..."
        active={loading}
      />
    </Container>
  );
};

export default MyEarnings;
