import React, { useState, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { BiChevronDown } from 'react-icons/bi';

import { useAuth } from '~/hooks/Auth';

import { Container, Menu } from './styles';

import logo from '~/assets/logo/logo-p-purple.svg';

import { web3store } from '~/store';

const HeaderPrivate: React.FC = () => {
  const { user, signOut } = useAuth();
  const location = useLocation();
  const history = useHistory();
  const [menuActive, setMenuActive] = useState('');
  const [active, setActive] = useState(false);
  const [connectedMember] = web3store.useState('connectedMember');

  useEffect(() => {
    // api.get(`binance-credentials/${user?.id}`).then((response) => {
    //   setHasExchanges(!!response.data);
    // });
  }, [user]);

  useEffect(() => {
    setActive(false);
  }, [location]);

  useEffect(() => {
    // console.clear();
    // console.log(connectedMember);
  }, [connectedMember]);

  return (
    <Container className="d-flex flex-lg-column justify-content-between justify-content-lg-around align-items-center text-center">
      <div className="w-100 d-flex flex-column pt-lg-4 pb-lg-3 ms-lg-0 text-start text-lg-center ms-4">
        <Link to="/dashboard">
          <img
            src={logo}
            alt="logo"
            className="width mx-auto d-none d-lg-block"
          />
          <img src={logo} alt="logo" className="width d-lg-none" />
        </Link>
      </div>
      <Menu active={active} className="w-100 text-end">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3 me-4"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="w-lg-100 d-flex flex-column justify-content-between justify-content-sm-around shadow-menu position-absolute p-lg-relative menu-group active overflow">
          <div className="w-100 menus position-relative">
            <div className="d-flex flex-column justify-content-start pt-3 pb-1 h-100 menu fixed position-absolute">
              <Link
                to={`${process.env.PUBLIC_URL}/dashboard`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start align-items-center ${
                  location.pathname.match(/\/dashboard.*/) ? 'active' : ''
                }`}
              >
                <div className="dashboard" />
                <p className="mb-0 ms-2">Dashboard</p>
              </Link>

              {false && (
                <Link
                  to={`${process.env.PUBLIC_URL}/perfil`}
                  className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/perfil.*/) ? 'active' : ''
                  }`}
                >
                  <div className="profile" />
                  <p className="mb-0 ms-2">Perfil</p>
                </Link>
              )}
              <button
                type="button"
                className={`${
                  (!connectedMember || !connectedMember.nft.is_active) &&
                  `nft-not-activate`
                } border-0 bg-transparent menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/activities.*/) ? 'active' : ''
                }`}
                onClick={() => setMenuActive('products')}
              >
                <div className="products" />
                <p className="mb-0 ms-2">
                  Products <BiChevronDown />
                </p>
              </button>
              <button
                type="button"
                className={`${
                  (!connectedMember || !connectedMember.nft.is_active) &&
                  `nft-not-activate`
                } border-0 bg-transparent menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/activities.*/) ? 'active' : ''
                }`}
                onClick={() => setMenuActive('business')}
              >
                <div className="business" />
                <p className="mb-0 ms-2">
                  Business <BiChevronDown />
                </p>
              </button>
              {false && (
                <Link
                  to={`${process.env.PUBLIC_URL}/learning`}
                  className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/learning.*/) ? 'active' : ''
                  }`}
                >
                  <div className="learning" />
                  <p className="mb-0 ms-2">Learning</p>
                </Link>
              )}
              {false && (
                <Link
                  to={`${process.env.PUBLIC_URL}/leaders`}
                  className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center bg justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/leaders.*/) ? 'active' : ''
                  }`}
                >
                  <div className="leaders" />
                  <p className="mb-0 ms-2">Leaders</p>
                </Link>
              )}
              {false && (
                <button
                  type="button"
                  className={`border-0 bg-transparent menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/trader.*/) ? 'active' : ''
                  }`}
                  onClick={() => setMenuActive('trader')}
                >
                  <div className="trader" />
                  <p className="mb-0 ms-2">
                    Trader <BiChevronDown />
                  </p>
                </button>
              )}
              {false && (
                <Link
                  to={`${process.env.PUBLIC_URL}/news`}
                  className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/news.*/) ? 'active' : ''
                  }`}
                >
                  <div className="news" />
                  <p className="mb-0 ms-2">News</p>
                </Link>
              )}
              <Link
                to={`${process.env.PUBLIC_URL}/token-vault`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/token-vault.*/) ? 'active' : ''
                }`}
              >
                <div className="token" />
                <p className="mb-0 ms-2">Token Vault</p>
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/subscriptions`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/subscriptions.*/) ? 'active' : ''
                }`}
              >
                <div className="subscriptions" />
                <p className="mb-0 ms-2">Subscriptions</p>
              </Link>
            </div>
            <div
              className={`d-flex flex-column justify-content-start pt-3 pb-1 vh-100 h-lg-100 menu position-absolute ${
                menuActive === 'business' ? 'active' : ''
              }`}
            >
              <button
                type="button"
                className="border-0 bg-transparent menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center"
                onClick={() => setMenuActive('')}
              >
                <div className="arrow" />
                <p className="mb-0 ms-2">Back</p>
              </button>
              <Link
                to={`${process.env.PUBLIC_URL}/business/referrals`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center bg justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/business\/referrals.*/)
                    ? 'active'
                    : ''
                }`}
              >
                <div className="referrers" />
                <p className="mb-0 ms-2">Referrals</p>
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/business/matrix`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center bg justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/business\/matrix.*/)
                    ? 'active'
                    : ''
                }`}
              >
                <div className="matrix" />
                <p className="mb-0 ms-2">Matrix</p>
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/business/wallet`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/business\/wallet.*/)
                    ? 'active'
                    : ''
                }`}
              >
                <div className="wallet" />
                <p className="mb-0 ms-2">Wallet</p>
              </Link>

              {false && (
                <>
                  <Link
                    to={`${process.env.PUBLIC_URL}/atividades/matriz-5x6`}
                    className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center bg justify-content-lg-start  align-items-center ${
                      location.pathname.match(/\/atividades\/matriz-5x6.*/)
                        ? 'active'
                        : ''
                    }`}
                  >
                    <div className="matrix" />
                    <p className="mb-0 ms-2">Matriz 5x6</p>
                  </Link>
                </>
              )}
              {false && (
                <Link
                  to={`${process.env.PUBLIC_URL}/business/links`}
                  className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/business\/links.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="links" />
                  <p className="mb-0 ms-2">Links</p>
                </Link>
              )}
              <Link
                to={`${process.env.PUBLIC_URL}/business/resources`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/resources.*/) ? 'active' : ''
                }`}
              >
                <div className="resources" />
                <p className="mb-0 ms-2">Resources</p>
              </Link>
            </div>
            <div
              className={`d-flex flex-column justify-content-start pt-3 pb-1 vh h-lg-100 menu position-absolute ${
                menuActive === 'products' ? 'active' : ''
              }`}
            >
              <button
                type="button"
                className="border-0 bg-transparent menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center"
                onClick={() => setMenuActive('')}
              >
                <div className="arrow" />
                <p className="mb-0 ms-2">Back</p>
              </button>
              <Link
                to={`${process.env.PUBLIC_URL}/products/paige`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/products\/paige.*/) ? 'active' : ''
                }`}
              >
                <div className="chatbot" />
                <p className="mb-0 ms-2">Paige</p>
              </Link>
              <Link
                to={`${process.env.PUBLIC_URL}/products/ai-art/generation`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/products\/ai-art.*/)
                    ? 'active'
                    : ''
                }`}
              >
                <div className="ai-art" />
                <p className="mb-0 ms-2">AI Art</p>
              </Link>
              {false && (
                <Link
                  to={`${process.env.PUBLIC_URL}/products/launchpad`}
                  className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                    location.pathname.match(/\/products\/launchpad.*/)
                      ? 'active'
                      : ''
                  }`}
                >
                  <div className="launchpad" />
                  <p className="mb-0 ms-2">Launchpad</p>
                </Link>
              )}

              <Link
                to={`${process.env.PUBLIC_URL}/products/cube`}
                className={`menu-item py-1 py-lg-3 w-100 d-flex justify-content-center justify-content-lg-start  align-items-center ${
                  location.pathname.match(/\/products\/cube.*/) ? 'active' : ''
                }`}
              >
                <div className="cube" />
                <p className="mb-0 ms-2">The Cube</p>
              </Link>
            </div>

            {(!connectedMember || !connectedMember.nft.is_active) && (
              <div className="position-absolute d-flex w-100 activate-nft">
                <button
                  type="button"
                  className="mx-auto py-2 px-3"
                  onClick={() =>
                    history.push(`${process.env.PUBLIC_URL}/how-to-participate`)
                  }
                >
                  ACTIVATE NFT
                </button>
              </div>
            )}
          </div>

          {/* <div className="w-100 d-flex justify-content-center">
            <div className="d-flex flex-column mt-30px pb-sm-5 pt-sm-2 logoff">
              <button
                type="button"
                className="border-0 bg-transparent menu-item d-flex justify-content-center justify-content-lg-start  align-items-center"
                onClick={signOut}
              >
                <span className="div logout" />
                <span className="mb-0 ms-2">&nbsp;&nbsp;Logout</span>
              </button>
            </div>
          </div> */}
        </div>
      </Menu>
    </Container>
  );
};

export default HeaderPrivate;
