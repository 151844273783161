import styled from 'styled-components';

interface IBoxNft {
  show: boolean;
  positionX: number;
  positionY: number;
}

interface IAvatar {
  src: string;
}

export const Container = styled.div`
  .bg-gray {
    background: #1d2023;
    border-radius: 20px;

    .border-right {
      border-color: #393c3d !important;
    }

    h2 {
      color: #dadada;
    }

    .input {
      border: 1.5px solid #393c3d;
      box-sizing: border-box;
      border-radius: 15px;
      height: 40px;

      input {
        color: #efefef !important;
      }
    }

    button.btn-gradient {
      border-radius: 15px;
    }
  }

  .table {
    .rdt_TableHeadRow,
    .rdt_TableRow {
      > div:nth-child(2),
      > div:nth-child(4) {
        max-width: 175px;
        width: 175px;
      }
    }
  }

  .contact {
    cursor: pointer;

    input {
      color: #8c8c8c;
      font-size: 13px;
      background-color: transparent;
      border: none;
    }
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #606060;
    span {
      color: #dadada;
    }
  }

  p {
    color: #969696;
  }

  button.border-gradient {
    height: 40px;
    padding: 1px;
  }

  .btn-group {
    background-color: #4b4c4c;
    position: relative;

    .btn-background {
      display: block;
      position: absolute;
      height: 35px;
      width: 152px;
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      border: 2px solid #d7d7d7;
      transition-duration: 0.3s;
    }

    .btn-background.right {
      left: 47%;
    }

    .btn-background.left {
      left: 0;
    }

    .btn-check {
      border: none;
      background: transparent;
      color: #bdbdbd;
      height: 35px;
      width: 152px;
      position: relative;
      z-index: 1;
      transition-duration: 0.3s;
    }

    .btn-check.active {
      color: #17171a;
    }
  }
`;

export const BoxMatrix = styled.div`
  .overdue-payment {
    border: 2px solid #ff1a50 !important;
  }

  .outstanding-payment {
    border: 2px solid #fbff4d !important;
  }

  .avatar {
    width: 50px;
    height: 50px;
    padding: 2px !important;
    cursor: unset;
  }
  .border-gradient:not(img) {
    padding: 1px;
  }
  .h-line {
    border-right: solid 1.5px #8c8c8c;
    height: 40px;
  }
  .text-light-gray {
    color: #cbccce;
  }

  .button-group {
    transition-duration: 0.3s;
  }

  .button-group.hide {
    opacity: 0;
    visibility: hidden;
  }

  .text-green {
    color: #96ffd9;
  }

  .btn-actions {
    height: 40px;
    width: 150px;
  }

  .box-top-user {
    background: #222527;
    border: 1px solid #5e5b5b;
    box-sizing: border-box;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    border-radius: 50px;

    img {
      width: 50px;
      height: 50px;
    }
  }

  .box-sub-user {
    background: #222527;
    border: 1px solid #5d5b5b;
    box-sizing: border-box;
    box-shadow: -2px 5px 16px rgba(0, 0, 0, 0.2);
    font-size: 90%;
    transition-duration: 0.3s;
    min-height: 50px;

    .default-avatar {
      width: 40px;
      height: 40px;
      background: #222527;
      border: 1px solid #5d5b5b;
      border-radius: 50%;
    }

    .w-custom {
      width: 80px;
      p {
        font-size: 12px;
      }
    }

    img {
      width: 40px;
      height: 40px;
    }

    :hover {
      border: 1px solid #cbccce;
    }
  }

  .box-user {
    width: 35px;
    height: 35px;
    background: #222527;
    border: 1px solid #5d5b5b;
    box-sizing: border-box;
    transition-duration: 0.3s;

    img {
      width: 36px;
      height: 36px;
    }

    :hover {
      border: 1px solid #cbccce;
    }
  }

  svg {
    width: 17px;
    height: 17px;
  }

  @media screen and (min-width: 1400px) {
  }

  @media screen and (min-width: 1200px) {
    .w-xl-20 {
      width: 20%;
    }
  }

  @media screen and (max-width: 767px) {
    .box-top-user {
      border-radius: 24px;
    }
  }
`;

export const BoxNft = styled.div<IBoxNft>`
  position: absolute;
  top: ${(props) => props.positionY}px;
  left: ${(props) => props.positionX}px;
  z-index: 2000;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
  transition: opacity 0.3s, visibility 0.3s;

  width: 344px;
  background: #222527;
  border-radius: 20px;

  .pay-amount {
    background: #6d50d0;
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
  }

  .message {
    background: transparent;
    border-radius: 16px;
    font-weight: 700;
    font-size: 20px;
    color: #ffffff;
    border: solid 1px #3778ff;
  }

  .pay-amount,
  .message {
    transition-duration: 0.3s;
    :hover {
      opacity: 0.9;
    }
  }

  .qualified {
    font-weight: 600;
    color: #dadada;
  }

  .bsPlus-border {
    border-radius: 11px;
    border: solid 1.5px #373738;
  }

  .wallpaper {
    margin-bottom: -72px;
  }

  .avatar-nft {
    width: 70px;
    height: 70px;
    border-radius: 22px;
    cursor: unset;
  }

  .img-nft {
    border-radius: 20px;
  }

  p {
    font-size: 24px;
    line-height: 22px;
    color: #cbccce;
  }

  .nft-name-selected {
    font-size: 18px;
    line-height: 22px;
    color: #dadada;
  }

  .nft-name,
  .new-member {
    color: #dadada;
    padding: 8px 22px;
    border-radius: 10px;
    background: rgba(13, 13, 13, 0.28);
    backdrop-filter: blur(2px);
  }

  .nft-name {
    font-weight: 700;
    font-size: 15px;
    line-height: 18px;
  }
  .new-member {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .p-box {
    border: 1px solid #373738;
    border-radius: 16px;
    padding: 12px;
    span {
      width: max-content;
    }
  }
`;

export const Avatar = styled.div<IAvatar>`
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: top;
`;
