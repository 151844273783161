import { lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IProps {
  top: number;
}

interface IActive {
  active: boolean;
}

interface IActiveToogleTop {
  active: boolean;
}

export const PeopleAttention = styled.div`
  left: 5%;
  bottom: 5%;
  z-index: 1000;
  filter: drop-shadow(4px 15px 40px rgba(0, 0, 0, 0.15));
  background: rgba(244, 247, 253, 0.6);
  border: 1px solid #ffffff;
  backdrop-filter: blur(5px);
  border-radius: 44px;
  h2 {
    font-weight: 700;
    font-size: 24px;
    color: #000000;
  }

  span {
    font-weight: 400;
    font-size: 18px;
    color: #3d3d3d;
    line-height: 10px;
  }
  div:nth-child(2) {
    font-family: 'Barlow';
    font-weight: 500;
    font-size: 100px;
    color: #7342ff;
  }

  @media screen and (max-width: 1799px) {
    zoom: 0.7;
  }

  @media screen and (max-width: 991px) {
    zoom: 0.5;
    bottom: 3%;
  }
`;

export const Banner = styled.div`
  font-family: 'Barlow', sans-serif;
  .nav .ancor .nav-text {
    display: none;
  }

  .nav:hover {
    /* width: 230px; */
    .ancor .nav-text {
      display: block !important;
    }
  }
  .nav {
    position: fixed;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    background: rgba(214, 203, 210, 0.2);
    background-blend-mode: multiply;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(5px);
    top: 50%;
    padding: 20px;
    right: 0;
    transform: translateY(-50%);
    display: flex;
    flex-flow: column;
    z-index: 201;
    /* width: 82px;
    transition: all 0.5s ease-in-out; */
  }

  .ancor {
    min-width: -webkit-fit-content;
    min-width: -moz-fit-content;
    min-width: fit-content;
    height: 46px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    opacity: 0.7;
    transition: opacity 1s;
    background: transparent;
    border: 0;
  }

  .nav-text {
    font-weight: 400;
    font-size: 15px;
    line-height: 125%;
    letter-spacing: 0.15px;
    margin-bottom: 0px;
  }

  .circle {
    position: relative;
    min-width: 46px;
    min-height: 46px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media screen and (min-width: 992px) {
    overflow: hidden;
  }

  @media screen and (max-width: 991px) {
    .nav {
      padding: 20px 7px;
    }
  }
`;

export const Circle = styled.div<IProps>`
  position: absolute;
  right: 31px;
  margin-top: 15px;
  display: flex;
  flex-flow: row;
  align-content: center;
  transition: top 1s ease-in-out, opacity 1s;
  top: calc(5px + (${(props) => props.top}px));
  opacity: 1;

  @media screen and (max-width: 991px) {
    right: 18px;
  }
`;

export const Video = styled.video`
  background-color: rgba(214, 203, 210);
  position: fixed;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -1000;
  opacity: 0.9;
  transform: translateX(-50%) translateY(-50%);
  transition: top 1s ease-in-out;
`;

export const ToogleTop = styled.div<IActiveToogleTop>`
  position: fixed;
  z-index: 10;
  top: ${(props) => (props.active ? 0 : -1000)}px;
  height: 65vh;
  width: 100%;
  background-color: #cbd3e3;
  color: #000;
  transition: top 1s ease-in-out;

  .xyz-in,
  .xyz-out {
    animation-duration: 0.3s;
  }

  .scroll {
    height: 40px;
  }

  .scroll-border {
    border: 2px solid #000;
    border-radius: 15px;
    width: 20px;
    height: 40px;
  }

  .scroll-circle {
    height: 20px;
    width: 10px;
    border-radius: 10px;
    background-color: #000;
    position: absolute;

    left: 2.5px;
    animation: scroll-down 1.5s infinite;
  }
  @keyframes scroll-down {
    from {
      top: 2.5px;
    }
    to {
      top: 12.5px;
    }
  }
`;

export const Footer = styled.div<IActive>`
  position: fixed;
  z-index: 10;
  bottom: ${(props) => (props.active ? 0 : -1000)}px;
  height: 50vh;
  width: 100%;
  background-color: #000;
  color: #fff;
  transition: bottom 1.3s ease-in-out;
`;

export const TextRide = styled.div`
  .btn-hover {
    padding: 0 3rem;
  }
  .btn-hover span:nth-child(2) {
    font-size: 1.75rem;
  }
  .xyz-in,
  .xyz-out {
    animation-duration: 1s;
  }

  .bg-circle-1 {
    height: 30px;
    width: 30px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    top: -2px;
    left: 10%;
  }

  .bg-circle-2 {
    height: 30px;
    width: 30px;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 15px;
    top: -2px;
    left: 24%;
  }

  .bg-circle-not-active {
    height: 16px;
    width: 16px;
    background: #ddd;
    border-radius: 15px;
    top: 5px;
  }

  .left-percentage-1 {
    left: 37%;
  }

  .left-percentage-2 {
    left: 50%;
  }

  .left-percentage-3 {
    left: 63%;
  }

  .left-percentage-4 {
    left: 76%;
  }

  .left-percentage-5 {
    left: 89%;
  }

  .bg-circle-1 svg,
  .bg-circle-2 svg {
    position: absolute;
    top: 7px;
    left: 7px;
  }

  .bg-circle-not-active svg {
    position: absolute;
    top: 4px;
    left: 4px;
  }

  .nav-11,
  .nav-57,
  .nav-149,
  .nav-195,
  .nav-241,
  .nav-287,
  .nav-333,
  .nav-379,
  .nav-425 {
    padding-top: 16vh;
    button:not(.btn-faq) {
      background: rgba(244, 247, 253, 0.5);
      backdrop-filter: blur(5px);
      font-size: 26px;
    }
  }

  .nav-11,
  .nav-57,
  .nav-103,
  .nav-149,
  .nav-195,
  .nav-241,
  .nav-379,
  .nav-287,
  .nav-333,
  .nav-425 {
    h1 {
      font-weight: 400;
      font-size: 40px;
      line-height: 57px;

      span {
        font-size: 48px;
        font-weight: 700;
      }
    }

    h3 {
      font-weight: 400;
      font-size: 26px;
      line-height: 31px;
      color: #000;
    }

    h5 {
      font-weight: 400;
      font-size: 26px;
      line-height: 31px;
    }

    hr {
      height: 4px;
      background-color: #000;
      opacity: 0.5;
    }
  }

  .max-w-road,
  .nav-287 div {
    max-width: 1320px;
  }

  .products a {
    background: rgba(244, 247, 253, 0.5);
    backdrop-filter: blur(5px);
    margin-bottom: 5rem;
  }

  .products h4 {
    margin-bottom: 60px;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #000;
  }

  .line-padding {
    padding-top: 25vh;
  }

  .box-date-bottom {
    //max-width: 210px;
    position: absolute;
    left: -89px;
    top: 40px;
    h3 {
      font-size: 40px;
      color: #000;
      font-weight: 300;
      background: rgba(244, 247, 253, 0.36);
      backdrop-filter: blur(10px);
      border-radius: 10px 10px 0 0;
    }

    h4 {
      font-size: 20px;
      color: #000;
      font-weight: 600;
      background: rgba(244, 247, 253, 0.72);
      backdrop-filter: blur(10px);
      border-radius: 0 0 10px 10px;
    }

    .triangule-bottom {
      height: 0;
      width: 0;
      border: 10px solid rgba(244, 247, 253, 0.36);
      border-left-color: transparent;
      border-right-color: transparent;
      border-top-color: transparent;
      background-color: transparent;
      left: calc(50% - 10px);
      position: relative;
    }
  }

  .box-date-top {
    //max-width: 210px;
    position: absolute;
    left: -89px;
    bottom: 40px;
    h3 {
      font-size: 40px;
      color: #000;
      font-weight: 300;
      background: rgba(244, 247, 253, 0.36);
      backdrop-filter: blur(10px);
      border-radius: 0 0 10px 10px;
    }

    h4 {
      font-size: 20px;
      color: #000;
      font-weight: 600;
      background: rgba(244, 247, 253, 0.72);
      backdrop-filter: blur(10px);
      border-radius: 10px 10px 0 0;
    }

    .triangule-top {
      height: 0;
      width: 0;
      border: 10px solid rgba(244, 247, 253, 0.36);
      border-left-color: transparent;
      border-right-color: transparent;
      border-bottom-color: transparent;
      background-color: transparent;
      left: calc(50% - 10px);
      position: relative;
    }
  }

  .box-date-not-active {
    position: absolute;
    left: -94px;

    h3 {
      font-size: 40px;
      color: #000;
      font-weight: 300;
    }

    h4 {
      font-size: 20px;
      color: #000;
      font-weight: 600;
    }
  }

  .bottom-not-active {
    top: 53px;
  }

  .top-not-active {
    bottom: 53px;
  }

  .btn-faq {
    font-family: 'Barlow', sans-serif;
    background: rgba(244, 247, 253, 0.36);
    backdrop-filter: blur(10px);
    border-radius: 5px;
    font-weight: 700;
    font-size: 28px;
    line-height: 34px;
    color: #000;
    border: none;
    min-height: 130px;
  }

  .btn-faq:hover {
    background: rgba(244, 247, 253, 0.72);
  }

  .btn-faq-active {
    border-radius: 5px 5px 0 0;
    background: rgba(244, 247, 253, 0.72);
  }

  .mg-top {
    margin-top: 12rem;
  }

  .w-10 {
    width: 10%;
  }

  .w-15 {
    width: 15%;
  }

  .w-20 {
    width: 20%;
  }

  .w-33 {
    width: 33.33%;
  }

  .w-40 {
    width: 40%;
  }

  .w-60 {
    width: 60%;
  }

  .w-65 {
    width: 65%;
  }

  .w-70 {
    width: 70%;
  }

  .w-85 {
    width: 85%;
  }

  .bg-box,
  .answer {
    background: rgba(244, 247, 253, 0.36);
    backdrop-filter: blur(10px);
    border-radius: 0 0 5px 5px;

    .btn-box {
      display: none;
    }
  }

  .answer {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000;
    text-align: center;
    min-height: 0px;
    transition: all 0.5s ease;
    p {
      display: none;
    }
  }

  .answer-active {
    min-height: 143px;
    p {
      display: block;
    }
  }

  .bg-box {
    height: 100%;
    padding: 2rem 3vw;
    border-radius: 5px;
    transition: all 0.3s ease;

    span {
      font-weight: 300;
      font-size: 20px;
      line-height: 24px;
      color: #343434;
    }

    h4 {
      font-size: 28px;
      font-weight: 700;
    }

    p {
      font-weight: 400;
      font-size: 18px;
      line-height: 22px;
      color: #000;
    }
  }

  .bg-box:hover {
    background: rgba(244, 247, 253, 0.65);
    margin-top: -60px;
    height: calc(100% + 60px);
  }

  .w-button {
    width: 20%;
  }

  .graph-width {
    width: 60%;
  }

  .title {
    position: absolute;
    padding-top: 16vh;
    width: 50%;
    z-index: 199;
  }
  .z-buttons {
    z-index: 200;

    .button {
      background: transparent;
      height: 100%;
      overflow: hidden;
      cursor: default;
    }

    .button:nth-child(1),
    .button:nth-child(2),
    .button:nth-child(3) {
      font-size: 18px;
      span:nth-child(1) {
        color: #343434;
      }
    }
  }

  .w-all-buttons {
    width: 75%;
  }

  .ride-top {
    position: absolute;
    margin-top: 10%;
    top: 0px;
    left: 5%;
    opacity: 0;
    transition: top 1.5s ease-in-out, opacity 0.8s;
    animation: rotate-top-out 1.5s;
    animation-iteration-count: 1;
  }
  @keyframes rotate-top-out {
    from {
      transform: rotatex(0deg);
    }
    to {
      transform: rotatex(90deg);
    }
  }

  .ride-bottom {
    position: absolute;
    margin-top: 10%;
    top: 250px;
    left: 5%;
    opacity: 0;
    transition: top 1.5s ease-in-out, opacity 0.8s;
    animation: rotate-bottom-out 1.5s;
    animation-iteration-count: 1;
  }
  @keyframes rotate-bottom-out {
    from {
      transform: rotatex(0deg);
    }
    to {
      transform: rotatex(-90deg);
    }
  }
  .show-top {
    top: 14vh;
    opacity: 1;
    animation: rotate-in 1.5s;
    animation-iteration-count: 1;
    z-index: 1000;
  }
  @keyframes rotate-in {
    from {
      transform: rotatex(90deg);
    }
    to {
      transform: rotatex(0deg);
    }
  }

  .show-bottom {
    top: 14vh;
    opacity: 1;
    animation: rotate-bottom 1.5s;
    animation-iteration-count: 1;
    z-index: 1000;
  }
  @keyframes rotate-bottom {
    from {
      transform: rotatex(-90deg);
    }
    to {
      transform: rotatex(0deg);
    }
  }

  @media screen and (max-width: 1799px) {
    .nav-11,
    .nav-57,
    .nav-149,
    .nav-195,
    .nav-241,
    .nav-287,
    .nav-333,
    .nav-379,
    .nav-425 {
      button:not(.btn-faq) {
        font-size: 22px;
      }
    }
    .nav-11,
    .nav-57,
    .nav-103,
    .nav-149,
    .nav-195,
    .nav-241,
    .nav-379,
    .nav-287,
    .nav-333,
    .nav-425 {
      h1 {
        font-size: 30px;
        line-height: 40px;
        span {
          font-size: 38px;
        }
      }

      h5 {
        font-size: 20px;
      }
    }

    .box-date-bottom,
    .box-date-top,
    .box-date-not-active {
      left: -58px;
      h3 {
        font-size: 30px;
      }
      h4 {
        font-size: 15px;
      }
    }

    .z-buttons .button:nth-child(1),
    .z-buttons .button:nth-child(2),
    .z-buttons .button:nth-child(3) {
      font-size: 16px;
    }

    .btn-hover {
      padding: 0 1.7vw;
    }

    .btn-hover span:nth-child(2) {
      font-size: 1.5rem;
    }

    .mg-top {
      margin-top: 16rem;
    }

    .bg-box {
      span {
        font-size: 18px;
      }
      h4 {
        font-size: 24px;
      }
      p {
        font-size: 16px;
      }
    }

    .products h4 {
      font-size: 20px;
    }

    .btn-faq {
      font-size: 1.3vw;
      line-height: 25px;
      min-height: 120px;
    }

    .answer {
      font-size: 14px;
      line-height: 17px;
    }

    .w-boxes {
      max-width: 94%;
    }
  }

  @media screen and (max-width: 1399px) {
    .btn-hover {
      padding: 0 1rem;
    }
  }

  @media screen and (max-width: 1199px) {
    .w-total {
      width: 60%;
    }
    .btn-faq {
      font-size: 1.2vw;
      min-height: 100px;
    }

    .answer {
      font-size: 12px;
    }
    .title {
      width: 65%;
    }

    .w-button {
      width: 25%;
    }

    .bg-box {
      padding: 2rem 2vw;
    }

    .bg-box {
      span {
        font-size: 16px;
      }
      h4 {
        font-size: 20px;
      }
      p {
        font-size: 14px;
      }
    }

    .graph-width {
      width: 75%;
    }
  }

  @media screen and (max-width: 991px) {
    .btn-hover span:nth-child(2) {
      font-size: 1.25rem;
    }
    .btn-faq {
      font-size: 17px;
    }
    .width-mobile {
      width: 130%;
      top: 0px;
    }
    .rotate-mobile {
      transform: rotate(90deg);
      margin-top: 51vw;
    }

    .line-padding {
      padding-top: 0;
    }

    .triangule-bottom {
      transform: rotate(90deg);
      top: 70px;
      left: calc(100% - 1px) !important;
    }

    .triangule-top {
      transform: rotate(90deg);
      top: -70px;
      left: -19px !important;
    }

    .box-date-bottom {
      transform: rotate(-90deg);
      left: -57px;
    }

    .box-date-top {
      transform: rotate(-90deg);
      left: -42px;
    }

    .box-date-not-active {
      transform: rotate(-90deg);
    }
    .answer-active {
      min-height: 83px;
    }
    .products a {
      margin-bottom: 2rem;
    }
    .products h4 {
      margin-bottom: 20px;
    }
    .bg-box {
      min-width: 180px;
      //height: 197px;
    }

    .bg-box:hover {
      margin-top: 0px;
      height: 100%;
    }

    .w-boxes {
      max-width: 100%;
      overflow-x: auto;
      min-height: 210px;

      ::-webkit-scrollbar {
        background-color: transparent !important;
        width: 0px !important;
        height: 0px !important;
      }

      ::-webkit-scrollbar-thumb {
        border-radius: 0px !important;
        background: transparent !important;
      }
    }
    .nav-11,
    .nav-57,
    .nav-103,
    .nav-149,
    .nav-195,
    .nav-241,
    .nav-379,
    .nav-287,
    .nav-333,
    .nav-425 {
      h1 {
        font-size: 24px;
        line-height: 29px;
        span {
          font-size: 34px;
          margin-top: 10px;
          display: block;
          line-height: 42px;
        }
      }

      h3 {
        font-size: 22px;
        line-height: 26px;
      }

      h5 {
        font-size: 18px;
        line-height: 22px;
      }
    }

    .nav-287,
    .nav-333 h1 {
      font-size: 34px;
      span {
        display: inline;
      }
    }

    .nav-195,
    .nav-241 {
      h1 {
        font-size: 34px !important;
        line-height: 42px;
      }
    }

    .z-buttons .button:nth-child(1),
    .z-buttons .button:nth-child(2),
    .z-buttons .button:nth-child(3) {
      font-size: 14px;
    }

    .w-button {
      width: 30%;
    }

    .w-lg-15 {
      width: 15%;
    }

    .btn-faq {
      min-height: 75px;
    }
  }

  @media screen and (max-width: 768px) {
    .width-mobile {
      width: 150%;
      top: 0px;
    }
    .w-all-buttons {
      width: 89%;
    }
    .graph-width {
      width: 60%;
    }

    .graph-width-mobile {
      width: 75%;
    }
  }

  @media screen and (max-width: 575px) {
    .width-mobile {
      width: 200%;
      top: 28px;
    }
    .nav-11,
    .nav-57,
    .nav-149,
    .nav-195,
    .nav-241,
    .nav-287,
    .nav-333,
    .nav-379,
    .nav-425 {
      button:not(.btn-faq) {
        font-size: 14px;
      }
    }

    .w-button {
      width: 50%;
    }

    .w-all-buttons {
      position: relative;
      top: 27rem;
    }

    .mg-top {
      margin-top: 0;
    }
    .w-total {
      width: 75%;
    }

    .w-sm-65 {
      width: 65%;
    }

    .title {
      width: 75%;
    }

    .graph-width {
      width: 75%;
    }

    .graph-width-mobile {
      width: 85%;
    }
  }

  @media screen and (max-width: 450px) {
    .w-all-buttons {
      width: 83%;
    }
  }

  @media screen and (max-width: 435px) {
    .w-all-buttons {
      top: 30rem;
    }
  }

  @media screen and (max-width: 400px) {
    .nav-333 h3 {
      font-size: 18px;
    }
    .box-date-bottom h4,
    .box-date-top h4 {
      font-size: 12px;
    }
  }

  @media screen and (max-width: 350px) {
    .nav-333 h3 {
      font-size: 16px;
    }
    .box-date-bottom h4,
    .box-date-top h4 {
      font-size: 11px;
    }

    .box-date-bottom {
      left: -49px;
    }

    .box-date-top {
      left: -24px;
    }

    .box-date-not-active {
      left: -38px;
    }

    .box-date-not-active h4 {
      font-size: 11px;
    }
    .nav-287 h1 {
      span {
        display: block;
      }
    }
    .w-all-buttons {
      top: 32rem;
    }
  }

  @media screen and (min-width: 576px) {
    .z-buttons {
      .button {
        background: transparent;
        height: 100%;
        overflow: hidden;
        cursor: default;
      }

      .button::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        background: rgba(244, 247, 253, 0.36);
        backdrop-filter: blur(10px);
        z-index: -1;
        top: 0;
        left: 0;
        transition: all 0.5s;
      }

      .btn-hover::before {
        width: 0;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .btn-hover:hover::before {
        width: 100%;
      }
    }

    .w-sm-15 {
      width: 15% !important;
    }

    .w-sm-60 {
      width: 60% !important;
    }

    .w-sm-85 {
      width: 85% !important;
    }
  }

  @media screen and (min-width: 992px) {
    .btn-hover span:nth-child(1),
    .btn-hover span:nth-child(2),
    .btn-hover span:nth-child(3) {
      width: 165px;
      margin-left: auto;
      margin-right: auto;
    }
    .w-lg-20 {
      width: 20% !important;
    }

    .w-lg-40 {
      width: 40% !important;
    }

    .w-lg-65 {
      width: 65%;
    }

    .w-lg-85 {
      width: 85%;
    }

    .w-lg-100 {
      width: 100% !important;
    }
  }

  @media screen and (min-width: 1800px) {
    .btn-hover span:nth-child(1),
    .btn-hover span:nth-child(2),
    .btn-hover span:nth-child(3) {
      width: 192px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;

export const LoadingHome = styled.div`
  .loading-box {
    height: 100%;
    background: rgba(214, 203, 210, 0.69);
    backdrop-filter: blur(7.5px);
    span {
      font-weight: 400;
      font-size: 20px;
      color: #6f51c4;
      text-align: center;
    }
  }

  .animation-loading {
    height: 152px;
    width: 152px;

    img {
      position: relative;
      z-index: 300;
      top: 10px;
      left: 10px;
      height: 134px;
      width: 134px;
      background: #ddd;
      backdrop-filter: blur(5px);
    }
  }

  .box-1 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #6f51c4;
    animation: position-box-1 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-1 {
    0% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    25% {
      top: calc(100% - 37px);
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    50% {
      top: calc(100% - 114px);
      left: 0;
      width: 37px;
      height: 114px;
    }

    75% {
      top: 0;
      left: 0;
      width: 114px;
      height: 37px;
    }

    100% {
      top: 0;
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }
  }

  .box-2 {
    position: absolute;
    width: 114px;
    height: 37px;
    background: #6f51c4;
    animation: position-box-2 3s linear infinite;
    z-index: 200;
  }

  @keyframes position-box-2 {
    0% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }

    25% {
      top: 0;
      left: 0;
      width: 37px;
      height: 114px;
    }

    50% {
      top: 0;
      left: calc(100% - 114px);
      width: 114px;
      height: 37px;
    }

    75% {
      top: calc(100% - 114px);
      left: calc(100% - 37px);
      width: 37px;
      height: 114px;
    }

    100% {
      top: calc(100% - 37px);
      left: 0;
      width: 114px;
      height: 37px;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  z-index: 2000;

  .animation-gray {
    background-color: rgba(244, 247, 253, 0.5);
    border-radius: 30px;
    padding: 8px;

    div {
      width: 198px;
      height: 198px;
      border-radius: 30px;
      background-color: #fff;
    }
  }

  .description {
    padding: 3rem 12rem;

    h3 {
      color: #000000;
      font-size: 32px;
      font-weight: 600;
      margin-bottom: 35px;
    }

    p {
      color: #000000;
      font-size: 18px;
    }
  }

  .bg-lg-social-medias {
    background: #0c0d0d;
    backdrop-filter: blur(5px);
    border-radius: 60px;
  }

  .social-medias {
    width: auto;
  }

  .social-medias img {
    width: 90px;
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 1199px) {
    .description {
      padding: 3rem 5rem;
    }
    .social-medias img {
      width: 75px;
    }
  }

  @media screen and (max-width: 991px) {
    .description {
      padding: 3rem 3rem;
    }

    .social-medias img {
      width: 85px;
    }

    .bg-mobile-social-medias {
      background: #0c0d0d;
      backdrop-filter: blur(5px);
      border-radius: 60px;
    }

    .bg-lg-social-medias {
      background: transparent;
      backdrop-filter: none;
      border-radius: unset;
    }
  }

  @media screen and (max-width: 575px) {
  }

  @media screen and (max-width: 420px) {
    .description {
      padding: 3rem 2rem;
    }
  }
`;
