import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';
import { lighten } from 'polished';
import background from '~/assets/banners/background.png';
import thumb from '~/assets/icons/thumb.svg';
import check from '~/assets/icons/check-icon.svg';

interface ICentersYearly {
  percentage: number;
}

interface INetworkYearly {
  percentage: number;
}
interface ICentersMonthly {
  percentage: number;
}

interface INetworkMonthly {
  percentage: number;
}

export const Banner = styled.div`
  background-color: #18191a;
  min-height: 100vh;

  .transition {
    transition-duration: 0.3s;
  }

  h1 {
    background: linear-gradient(269.95deg, #fff 0.02%, #fff 97.45%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 48px;
    > span {
      background: linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      );
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
    }
  }

  p {
    color: #f4f4f4;
    font-weight: 300 !important;
  }

  .text-gray {
    color: #b7b7b7;
  }

  b {
    font-weight: bold;
  }

  .wallet-avatar {
    position: absolute;
    top: 2rem;
  }

  .reference {
    display: none !important;
  }

  .padding-btn {
    padding: 0 2rem;
  }

  .btn-calculator {
    max-width: 887px;
    cursor: pointer;
    border: none;
    padding: 1px;
    font-size: 24px;
    filter: drop-shadow(0px 12px 10px rgba(0, 0, 0, 0.2));
    background-image: linear-gradient(180deg, #17171a, #17171a),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    transition-duration: 0.3s;

    span {
      padding: 16px 10px;
      display: block;
      background: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }

  .btn-pay {
    //max-width: 817px;
    cursor: pointer;
    border: none;
    padding: 1px;
    font-size: 20px;

    background-image: linear-gradient(180deg, #222, #222),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 18px;
    transition-duration: 0.3s;

    span {
      padding: 15px 10px;
      display: block;
      background: #fff;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-weight: 700;
    }
  }

  .btn-pay-gray {
    //max-width: 817px;
    cursor: pointer;
    border: 1px solid #4f4f4f;
    padding: 1px;
    font-size: 20px;
    background: #222;
    border-radius: 18px;
    transition-duration: 0.3s;

    span {
      padding: 15px 10px;
      display: block;
      color: #fff;

      font-weight: 700;
    }
  }

  .earnings-title {
    color: #e9e9e9;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 10px;
  }
  .amount {
    color: #827777;
    font-size: 12px;
    font-weight: 400;
    line-height: 125%;
  }

  .btn-percentage {
    //max-width: 860px;
  }

  .btn-percentage button {
    background: #0c0b0b;
    padding: 1px;
    color: #7a7a7a;
    font-weight: 500;
    width: 228px;
    span {
      background: #0c0b0b;
      font-size: 20px;
      padding: 12px;
    }
  }

  .btn-percentage button:hover,
  .btn-percentage .active {
    background: linear-gradient(
      239.23deg,
      #9df3ff 100%,
      #bcffe5 100%,
      #deefd1 100%,
      #ffe0be 100%,
      #fbc2d3 100%,
      #fabcd6 100%,
      #f281ff 100%
    );
    padding: 1px;
    color: #fff;
    font-weight: 700;
    span {
      background: #222222;
      font-size: 20px;
    }
  }

  .text-gradient {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
  }

  .video-container {
    > div {
      width: 80px;
      height: 50%;
      background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0.096) 100%
      );
      opacity: 0.3;
      box-shadow: inset 0px 39px 52px -29px rgba(255, 255, 255, 0.5),
        inset 6px 1px 1px -4px #ffffff, inset -6px -7px 8px -4px #ffffff,
        inset 0px 7px 3px -4px #ffffff,
        inset 51px -73px 72px -60px rgba(255, 255, 255, 0.3),
        inset 0px 98px 100px -48px rgba(255, 255, 255, 0.3),
        inset 0px 4px 18px rgba(255, 255, 255, 0.3),
        inset 0px 1px 40px rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(2px);
      border-radius: 30px;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }

    div.left {
      left: -10px;
    }

    video {
      width: 100%;
      border-radius: 50px;
    }

    div.right {
      right: -10px;
    }
  }

  .eth-amt-wrap {
    width: 227px;
    padding: 10px;
    border-radius: 20px;
    background: #1c1c1c;
    .inner {
      .txt {
        display: flex;

        color: #7d7777;
        font-family: 'Poppins', sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        img {
          height: 40px;
          width: 40px;
        }
      }
    }
  }

  @media screen and (min-width: 992px) {
    .zoom-order {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .zoom-order {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .zoom-order {
      zoom: 1;
    }
  }

  @media screen and (max-width: 1799px) {
    .btn-percentage button:hover,
    .btn-percentage .active {
      padding: 1.3px 1.4px;
    }
    .btn-calculator {
      max-width: 887px;
    }
  }

  @media screen and (max-width: 1399px) {
    .padding-btn {
      padding: 0.25rem;
    }

    .btn-percentage button:hover,
    .btn-percentage .active {
      padding: 1.5px 1.4px;
    }
  }

  @media screen and (max-width: 991px) {
    .padding-btn {
      padding: 0 1.5rem;
    }
  }

  @media screen and (max-width: 575px) {
    .btn-percentage {
      width: 575px;
    }

    .overflow-small {
      overflow: auto;
      ::-webkit-scrollbar-thumb {
        background: transparent !important;
      }
    }
  }

  @media screen and (max-width: 524px) {
    h1 {
      > span {
        background: unset;
        background-clip: unset;
        -webkit-background-clip: unset;

        span {
          background: linear-gradient(
            239.23deg,
            #9df3ff 7.84%,
            #bcffe5 28.62%,
            #deefd1 38.94%,
            #ffe0be 49.96%,
            #fbc2d3 64.82%,
            #fabcd6 67.63%,
            #f281ff 91.88%
          );
          background-clip: text;
          -webkit-background-clip: text;
          color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }

  @media screen and (max-width: 399px) {
    h1 {
      font-size: 9vw;
    }
  }
`;

export const Card = styled.div`
  .mg-bottom {
    margin-bottom: 38px;
  }

  button.btn-calculator {
    transition-duration: 0.3;

    :hover {
      opacity: 0.7;
    }
  }

  .color-p {
    color: #828282;
  }

  .card-price {
    border: none;
    padding: 2px;
    font-size: 20px;

    background-image: linear-gradient(180deg, #282828, #282828),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 30px;

    ul {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        :before {
          content: '';
          background: #f2f2f2;
          background-image: url(${check});
          background-position: center;
          background-size: 13px;
          background-repeat: no-repeat;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: block;
        }

        small {
          color: #f2f2f2;
          width: 85%;
        }
      }
    }
  }

  .card-price-gray {
    border: 2px solid #4f4f4f;
    padding: 2px;
    font-size: 20px;
    background: #282828;
    border-radius: 30px;

    ul {
      list-style: none;

      li {
        display: flex;
        align-items: center;
        justify-content: space-around;
        margin: 10px 0;

        :before {
          content: '';
          background: #f2f2f2;
          background-image: url(${check});
          background-position: center;
          background-size: 13px;
          background-repeat: no-repeat;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          width: 20px;
          height: 20px;
          border-radius: 50%;
          display: block;
        }

        small {
          color: #f2f2f2;
          width: 85%;
        }
      }
    }
  }

  .commom-price {
    .value {
      margin: 0 auto;

      span {
        left: 0;
        top: 20px;
        font-weight: bold !important;
        font-size: 1.6rem;
      }
      p {
        font-size: 8rem;
        font-weight: bold !important;
      }
    }
  }

  .best-price {
    .value {
      span {
        left: 0;
        top: 20px;

        font-size: 2rem;
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }

      p {
        font-size: 40px;
        font-style: italic;
        text-align: center;
        font-weight: bold !important;
        background: linear-gradient(
          239.23deg,
          #9df3ff 7.84%,
          #bcffe5 28.62%,
          #deefd1 38.94%,
          #ffe0be 49.96%,
          #fbc2d3 64.82%,
          #fabcd6 67.63%,
          #f281ff 91.88%
        );
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }
    .value-token {
      span {
        left: 0;
        top: 20px;
        font-weight: bold !important;
        font-size: 2rem;
        color: #e9e9e9;
      }
      p {
        font-size: 72px;
        font-weight: 600 !important;
        color: #e9e9e9;
        line-height: 70px;
      }
    }
  }

  .slider-pic {
    position: relative;
    z-index: 1;
    -webkit-appearance: none;
    height: 2px;
    border-radius: 2px;
    background: #595757;
    outline: none;
    opacity: 1;
  }

  .slider-pic::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 50px;
    height: 50px;
    border: 0;
    background: url(${thumb});
    background-position: center;
    background-size: cover;
    cursor: pointer;
  }

  .btn-gradient-light,
  .btn-gradient-gray {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border-radius: 20px;
    padding: 16px 20px;
    color: #000;
    font-size: 20px;
    font-weight: 700;
    transition: 0.3s;
    border: 2px solid #ffffff;
  }

  .btn-gradient-light:hover {
    background: linear-gradient(
      239.23deg,
      #f281ff 7.84%,
      #fabcd6 28.62%,
      #fbc2d3 38.94%,
      #ffe0be 49.96%,
      #deefd1 64.82%,
      #bcffe5 67.63%,
      #9df3ff 91.88%
    );
  }

  .btn-gradient-gray {
    background: #bdbcbc !important;
    :hover {
      background-color: ${lighten(0.1, '#bdbcbc')}!important;
    }
  }

  @media screen and (min-width: 992px) {
    .max-width {
      max-width: 315px;
    }

    .max-wid {
      max-width: 417px;
    }
  }

  @media screen and (max-width: 991px) {
    .commom-price {
      .value {
        width: 55%;

        span {
          font-size: 1.4rem;
        }
      }
    }
  }

  @media screen and (max-width: 575px) {
    .best-price {
      .value,
      .value-token {
        p {
          font-size: 5.25rem;
        }
      }
    }
  }
`;

export const Modal = styled(ModalComponent)`
  h2 {
    color: #dadada;
    font-size: 64px;
    font-family: 'Poppins', sans-serif !important;
  }

  h3 {
    font-weight: 400;
    font-size: 24px;
    color: #dadada;
  }

  h6 {
    font-weight: 400;
    font-size: 12px;
    color: #dadada;
  }

  .btn-calculator button {
    width: 25%;
    background: #222;
    padding: 1px;
    color: #7a7a7a;
    font-weight: 500;
    span {
      background: #222;
      font-size: 20px;
    }
  }

  .btn-calculator button:hover,
  .btn-calculator .active {
    background: linear-gradient(
      239.23deg,
      #9df3ff 100%,
      #bcffe5 100%,
      #deefd1 100%,
      #ffe0be 100%,
      #fbc2d3 100%,
      #fabcd6 100%,
      #f281ff 100%
    );
    padding: 1px;
    color: #fff;
    font-weight: 700;
    span {
      background: #222222;
      font-size: 20px;
    }
  }

  .h-main-progress {
    height: 54px;
  }

  .progress {
    background: rgba(34, 34, 34, 30%);
    border: 1px solid rgba(122, 122, 122, 30%);
    border-radius: 30px;
    height: 50px;
  }

  .selector {
    position: absolute;
    width: 22px;
    height: 54px;
    left: calc(45% - 11px);
    top: -2px;
    background-color: #ffffff;
    border: 1px solid #ececec;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.08),
      inset 1px 1px 2px rgba(255, 255, 255, 0.3),
      inset -1px -1px 2px rgba(200, 199, 199, 0.5);
    border-radius: 20px;
  }

  .selector-gray {
    position: absolute;
    width: 38px;
    height: 54px;
    left: 0;
    top: -1px;
    background: #88898b;
    border: 1px solid #b3b3b3;
    border-radius: 15px;
  }

  .slider-progress-bar {
    position: relative;
    z-index: 1;
    width: 100%;
    -webkit-appearance: none;
    background: rgba(34, 34, 34, 0%);

    border: 1px solid rgba(122, 122, 122, 30%);
    border-radius: 30px;
    height: 50px;
    outline: none;
    opacity: 1;
  }

  .slider-progress-bar::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 54px;
    border: 0;
    background-color: #ffffff;
    border: 1px solid #ececec;

    cursor: pointer;
    border-radius: 20px;
  }

  .progress-bar-profit {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
    position: absolute;

    top: 0px;
    height: 50px;
  }

  .progress-bar-network {
    background: linear-gradient(267.67deg, #e323ff 0.98%, #366dfa 132.89%);
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
  }

  .progress-cost {
    background: #00f9ab;
    border-radius: 16px 15px 15px 16px;
    border: 1px solid #efefef;
    height: 54px;
    box-shadow: 2px 2px 12px rgba(0, 0, 0, 0.05);
  }

  .progress-bar-cost {
    border-radius: 15px 0px 0px 15px;
    height: 52px;
  }

  .cost,
  .earning {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    position: relative;
    color: #5c5e61;
    width: 120px;

    ::before {
      content: '';
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      position: absolute;
      top: 5px;
    }
  }

  .cost::before {
    background: #9df3ff;
  }

  .earning::before {
    background: #884bfd;
    left: -15px;
  }

  .value-cost {
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;

    color: #c4c4c4;
  }

  .value-earnings {
    font-weight: 800;
    font-size: 28px;
    line-height: 34px;

    color: #ffffff;
  }

  .values-total-members {
    font-weight: 400;
    font-size: 14px;
    color: #6e6e6e;
  }

  .bg-value-revenue {
    background: #1d1c1c;
    height: 54px;
    border-radius: 40px;
    margin-top: 1.25rem;
  }

  .value-revenue {
    border: none;
    padding: 2px;
    font-size: 18px;
    color: #fff;
    height: 42px;
    font-weight: 700;
    filter: drop-shadow(0px 12px 10px rgba(0, 0, 0, 0.2));
    background-image: linear-gradient(180deg, #1d1c1c, #1d1c1c),
      linear-gradient(
        239.23deg,
        #9df3ff 7.84%,
        #bcffe5 28.62%,
        #deefd1 38.94%,
        #ffe0be 49.96%,
        #fbc2d3 64.82%,
        #fabcd6 67.63%,
        #f281ff 91.88%
      ) !important;
    background-origin: border-box !important;
    background-clip: content-box, border-box !important;
    border-image-slice: 1 !important;
    border-radius: 20px;
    transition-duration: 0.3s;
  }

  .bg-number {
    background: rgba(34, 34, 34, 0.3);
    border: 1px solid rgba(57, 58, 61, 0.3);
    border-radius: 20px;
  }

  .number {
    font-size: 64px;
    color: #dadada;
    border-bottom: 1px solid #393c3d;
  }

  .color {
    color: #5e5e5e;
  }

  .modal-close {
    color: #e9e9e9;
    height: 50px;
    width: 50px;
  }

  .modal-xl {
    width: 1140px;
  }

  .network {
    span:nth-child(2) {
      margin-left: 14%;
    }

    span:nth-child(3) {
      margin-left: 12%;
    }

    span:nth-child(4) {
      margin-left: 11%;
    }

    span:nth-child(5) {
      margin-left: 9%;
    }

    span:nth-child(6) {
      margin-left: 8%;
    }

    span:nth-child(7) {
      margin-left: 7%;
    }
  }

  @media screen and (max-width: 1799px) {
    .modal-dialog {
      zoom: 0.8;
    }

    .btn-calculator button:hover,
    .btn-calculator .active {
      padding: 2px;
    }
  }

  @media screen and (max-width: 1399px) {
    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 28px;
    }
    h6 {
      font-size: 18px;
    }

    .btn-calculator button {
      width: 40%;
    }

    .value-cost {
      font-size: 18px;
    }

    .value-earnings {
      font-size: 20px;
    }

    .bg-value-revenue {
      margin-top: 0;
    }

    .network {
      span:nth-child(2) {
        margin-left: 14%;
      }

      span:nth-child(3) {
        margin-left: 10%;
      }

      span:nth-child(4) {
        margin-left: 10%;
      }

      span:nth-child(5) {
        margin-left: 8%;
      }

      span:nth-child(6) {
        margin-left: 6%;
      }

      span:nth-child(7) {
        margin-left: 5%;
      }
    }
  }

  @media screen and (max-width: 575px) {
    .modal-xl {
      width: 500px;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media screen and (max-width: 359px) {
    .modal-xl {
      width: 450px;
      margin-left: auto;
      margin-right: auto;
    }

    .network {
      span:nth-child(2) {
        margin-left: 13%;
      }

      span:nth-child(3) {
        margin-left: 10%;
      }

      span:nth-child(4) {
        margin-left: 9%;
      }

      span:nth-child(5) {
        margin-left: 7%;
      }

      span:nth-child(6) {
        margin-left: 5%;
      }

      span:nth-child(7) {
        margin-left: 5%;
      }
    }
  }
`;

export const ModalNeed = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .fw-600 {
      font-weight: 600;
    }

    .texts p:nth-child(1) {
      color: #7d7777;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 35px;
      letter-spacing: -1.04px;
    }

    .texts p:nth-child(2) {
      color: #dadada;
      text-align: center;
      font-family: 'Quicksand';
      font-size: 26px;
      font-style: normal;
      font-weight: 400;
      line-height: 35.5px;
      letter-spacing: -1.04px;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-c14 {
      background: #ffae52;
      border-radius: 20px;
      border: none;
      color: #000;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-uphold {
      background: #05da8d;
      border-radius: 20px;
      border: none;
      color: #000;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-xcoins {
      background: #686ef9;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-paybis {
      background: #116bff;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      background: #2b3247;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .modal-lg {
      --bs-modal-width: 617px;
    }

    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .modal-dialog {
      zoom: 1;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;

export const ModalAmount = styled(ModalComponent)`
  .modal-content {
    background: #171719 !important;
    border-radius: 40px;
    position: relative;

    h2 {
      font-family: 'Poppins';
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      color: #dadada;
    }

    .modal-close {
      color: #e9e9e9;
      position: absolute;
      right: 20px;
      top: 20px;
    }

    .fw-600 {
      font-weight: 600;
    }

    .bg-coin {
      border-radius: 20px;
      background: #1c1c1c;
      padding: 10px 22px;

      img {
        width: 40px;
        height: 40px;
      }

      span {
        color: #7d7777;
        font-family: 'Poppins';
        font-size: 20px;
        font-weight: 500;
      }
    }

    .value-eth {
      color: #e9e9e9;
      font-family: 'Poppins';
      font-size: 16px;
      font-weight: 600;

      span {
        font-weight: 300;
      }
    }

    .value {
      display: block;
      color: #fff;
      font-family: 'Poppins';
      font-size: 80px;
      font-weight: 300;
      text-align: right;
    }

    .bg-eth {
      margin-top: 40px;
      margin-bottom: 12px;
      border-radius: 20px;
      background: linear-gradient(78deg, #0554bb 0%, #0b65db 100%);
      padding: 25px 17px;
      button {
        border-radius: 30px;
        border: 1px solid #c6bdbd;
        background: rgba(0, 0, 0, 0.4);
        color: #c6bdbd;
        font-family: 'Poppins';
        font-size: 14px;
        font-weight: 400;
        padding: 8px 10px;
      }
    }

    .bg-btns-value {
      border-radius: 20px;
      background: #1c1c1c;
      padding: 11px;
    }

    .btn-not-active {
      color: #7d7777;
      font-family: 'Poppins';
      font-size: 30px;
      font-weight: 500;
      background: transparent;
      border: 1px solid #1c1c1c;
      flex-grow: 1;
      height: 58px;
      border-radius: 21px;
      transition-duration: 0.2s;

      :hover {
        color: #171719;
        font-weight: 500;
        border: 1px solid #c6bdbd;
        background: #fff;
      }
    }

    .btn-active {
      color: #171719;
      font-weight: 500;
      border: 1px solid #c6bdbd;
      background: #fff;
      font-family: 'Poppins';
      font-size: 30px;
      flex-grow: 1;
      height: 58px;
      border-radius: 21px;
    }

    .texts .terms {
      background: #1d1d1f;
      border-radius: 12px;
      font-weight: 300;
      font-size: 16px;
      padding: 10px;
      span {
        font-weight: 300;
        font-size: 16px;
        line-height: 32px;
        color: #7d7777;
      }
    }

    .color-span {
      color: #a6a2a2 !important;
      font-weight: 500 !important;
    }

    .btn-confirm {
      background: #696ffd;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }

    .btn-cancel {
      background: #2b3247;
      border-radius: 20px;
      border: none;
      color: #fff;
      font-weight: 700;
      font-size: 24px;
      padding: 20px;
      transition-duraton: 0.3s;
      :hover {
        opacity: 0.8;
      }
    }
  }

  @media screen and (min-width: 992px) {
    .modal-lg {
      --bs-modal-width: 617px;
    }

    .modal-dialog {
      zoom: 0.7;
    }
  }

  @media screen and (min-width: 1200px) {
    .modal-dialog {
      zoom: 0.8;
    }
  }

  @media screen and (min-width: 1400px) {
    .modal-dialog {
      zoom: 1;
    }
  }

  @media screen and (max-width: 991px) {
    h2 {
      font-size: 24px;
    }
    .modal-content img {
      margin: -1rem 0 -4rem 0;
    }
  }
`;

export const RangeCentersYearly = styled.div<ICentersYearly>`
  .progress-bar-centers {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
    position: absolute;
    width: ${(props) => props.percentage}%;
    top: 0px;
    height: 50px;
  }
`;

export const RangeCentersMonthly = styled.div<ICentersMonthly>`
  .progress-bar-centers {
    background: linear-gradient(
      239.23deg,
      #9df3ff 7.84%,
      #bcffe5 28.62%,
      #deefd1 38.94%,
      #ffe0be 49.96%,
      #fbc2d3 64.82%,
      #fabcd6 67.63%,
      #f281ff 91.88%
    );
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
    position: absolute;
    width: ${(props) => props.percentage}%;
    top: 0px;
    height: 50px;
  }
`;

export const RangeNetworkYearly = styled.div<INetworkYearly>`
  .progress-bar-network {
    background: linear-gradient(267.67deg, #e323ff 0.98%, #366dfa 132.89%);
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
    position: absolute;
    width: ${(props) => props.percentage}%;
    max-width: 99%;
    top: 0px;
    height: 50px;
  }
`;

export const RangeNetworkMonthly = styled.div<INetworkMonthly>`
  .progress-bar-network {
    background: linear-gradient(267.67deg, #e323ff 0.98%, #366dfa 132.89%);
    border: 1px solid #f2f2f2;
    border-radius: 27.5px 0px 0px 27.5px;
    position: absolute;
    width: ${(props) => props.percentage}%;
    max-width: 98%;
    top: 0px;
    height: 50px;
  }
`;
